import { A7LayoutService, A7PageSizeMatcher } from '@ark7/layout';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';

import { BackgroundColors } from '../models/landing-page';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent implements OnInit {
  @HostBinding('class')
  @Input()
  theme: string = 'default';

  @Input()
  headerMode: 'full' | 'login' | 'signup' | 'social' | 'none' = 'full';

  @Input()
  headerLogoLink: string = '/';

  @Input()
  footerShowNavi: boolean = true;

  @Input()
  headerBackground: BackgroundColors;

  @Input()
  showHeader: boolean = true;

  @Input()
  showFooter: boolean = true;

  handset: boolean = false;

  @HostBinding('class.mobile')
  @A7PageSizeMatcher('XS')
  xs: boolean;

  constructor(
    public host: ElementRef,
    breakpointObserver: BreakpointObserver,
    public layout: A7LayoutService,
  ) {
    breakpointObserver
      .observe([Breakpoints.HandsetPortrait])
      .subscribe((result) => {
        this.handset = result.matches;
      });
  }

  ngOnInit() {}

  get offsetHeight(): number {
    return this.host.nativeElement.offsetHeight;
  }
}
