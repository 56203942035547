import _ from 'underscore';
import debug from 'debug';
import {
  IResourceAction,
  IResourceActionInner,
  ResourceAction,
  ResourceActionReturnType,
} from '@ngx-resource/core';
import { Model, ModelizeError, StrictModel } from '@ark7/model';
import { ReplaySubject, Subject, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { A7Resource } from './resource';
import { A7Resource2Module } from './resource2.module';
import {
  IPaginationData,
  PaginationData,
  isPaginationData,
} from './pagination';

const d = debug('a7-resource:A7ResourceAction');

export interface RequestContext {
  pagination?: IPaginationData<any>;
  respArray?: any[];
  observer?: Subject<any>;
  resource?: A7Resource;
  propertyName?: string;
}

export interface A7ResourceActionOptions<M = any, R = any>
  extends IResourceAction {
  // map?: IA7ResourceResponseMap<M, R>;
  model?: typeof Model;
  pagination?: boolean;
  recover?: (err: any, resource: R) => any;
  asObservable?: boolean;
  isArray?: boolean;
  cacheMilli?: number;
}
class CacheManager {
  private cache: Map<string, { timestamp: number; data: any; ttl: number }> =
    new Map();

  set(key: string, data: any, ttl: number) {
    this.cache.set(key, { timestamp: Date.now(), data, ttl });
    setTimeout(() => this.cache.delete(key), ttl);
  }

  get(key: string): any | null {
    const cached = this.cache.get(key);
    if (cached && Date.now() - cached.timestamp < cached.ttl) {
      return cached.data;
    }
    return null;
  }
}

const cacheManager = new CacheManager();

function ensureObserver(inner: IResourceActionInner & RequestContext) {
  if (inner.observer == null) {
    inner.observer = new ReplaySubject(1);
  }
}

/**
 * Define an action resource.
 *
 * @param path - The path of the action.
 * @param method - The HTTP method of the action.
 * @param asObservable - Whether the response is an observable.
 * @param pagination - Whether the response is a pagination data.
 * @param model - The model of the response.
 */
export function A7ResourceAction<
  M extends object = any,
  R extends A7Resource = any,
>(options: A7ResourceActionOptions<M, R> = {}): PropertyDecorator {
  function mapResponse(
    model: M | IPaginationData<M>,
    o: IResourceActionInner & RequestContext,
  ) {
    ensureObserver(o);

    if (options.pagination && isPaginationData(model)) {
      const pagination = PaginationData.modelize(model, {
        meta: {
          $observer: o.observer as any,
          $modifier: o.actionAttributes.query,
          $resource: o.resource,
          $path: o.propertyName,
        },
        model: o.actionOptions.model,
        attachFieldMetadata: true,
        allowReference: true,
      });

      return pagination;
    }

    if (o.actionOptions.model == null) {
      return model;
    }

    if (_.isFunction(o.actionOptions.model.modelize)) {
      try {
        return (o.actionOptions.model as typeof Model).modelize(model as any, {
          meta: {
            $observer: o.observer as any,
            $modifier: o.actionOptions.query,
            $resource: o.resource,
          },
          attachFieldMetadata: true,
          allowReference: true,
        });
      } catch (error) {
        if (error instanceof ModelizeError) {
          console.error(`Modelize ${error.path} failed`);
          throw error.cause;
        } else {
          throw error;
        }
      }
    }

    if (_.isFunction(o.actionOptions.model)) {
      return o.actionOptions.model(model);
    }

    return model;
  }

  const actions = ResourceAction(
    _.extend(
      {
        returnAs: options.asObservable
          ? ResourceActionReturnType.Observable
          : ResourceActionReturnType.Promise,
      },
      options,
      {
        map: mapResponse,
      },
    ),
  );

  return (prototype: any, propertyName: string) => {
    actions(prototype, propertyName);
    const func = prototype[propertyName];

    prototype[propertyName] = function (this: R, ...args: any[]) {
      _.each(args, (arg, idx) => {
        if (_.isObject(arg) && !_.isDate(arg) && !_.isFunction(arg)) {
          const keys = _.chain(_.pairs(arg))
            .filter(([_key, val]) => _.isUndefined(val))
            .map(([key]) => key)
            .value();

          if (!_.isEmpty(keys)) {
            args[idx] = _.omit(arg, keys);
          }
        }
      });

      const cacheKey = JSON.stringify({ path: propertyName, args });
      const cachedValue = options.cacheMilli
        ? cacheManager.get(cacheKey)
        : null;

      let result: Promise<any>;
      if (cachedValue) {
        d(`cache hit ${cacheKey}`);
        result = Promise.resolve(cachedValue);
      } else {
        result = func.apply(this, args);
        if (options.cacheMilli) {
          // todo handle observable case
          result.then?.((data) => {
            cacheManager.set(cacheKey, data, options.cacheMilli);
          });
        }
      }

      const inner = this.$lastOptions;
      inner.resource = this;
      inner.propertyName = propertyName;

      const o = _.extend({}, this.getResourceOptions(), options);

      const onValue = (val: any) => {
        if (_.isArray(val)) {
          _.each(val, (data, idx) => {
            if (data instanceof StrictModel) {
              data.$attach({
                $isArray: true,
                $parent: val,
                $index: idx,
              });
            }
          });
        }

        const { signalTopic, subSignalTopic } = inner.actionOptions;
        if (signalTopic && subSignalTopic) {
          const topic = _.chain([signalTopic, subSignalTopic, val._id])
            .filter((x) => !!x)
            .value()
            .join(':');
          A7Resource2Module.getSignalService()
            .getTopic(topic)
            .next({ topic, resourceId: val?._id, data: val });
        }
      };

      const onError = (err: any) => {
        const { signalTopic, subSignalTopic } = inner.actionOptions;
        if (signalTopic && subSignalTopic) {
          const topic = _.chain(['$error', signalTopic, subSignalTopic])
            .filter((x) => !!x)
            .value()
            .join(':');
          A7Resource2Module.getSignalService()
            .getTopic(topic)
            .next({ topic, resourceId: null, err });
        }
      };

      if (o.asObservable) {
        ensureObserver(inner);
        inner.mainObservable.subscribe(
          (obj) => {
            onValue(obj);
            inner.observer.next(obj);
          },
          (err) => {
            onError(err);
            inner.observer.error(err);
          },
        );
        const observer = inner.observer;
        return o.recover
          ? observer.pipe(
              catchError((err) => {
                d('Caught error: %O', err);
                try {
                  return of(o.recover(err, this));
                } catch (error) {
                  return throwError(error);
                }
              }),
            )
          : observer;
      }

      return new Promise((resolve, reject) => {
        result.then(
          (data) => {
            onValue(data);
            resolve(data);
          },
          (err) => {
            onError(err);
            if (o.recover) {
              try {
                resolve(o.recover(err, this));
              } catch (error) {
                reject(error);
              }
            } else {
              reject(err);
            }
          },
        );
      });
    };
  };
}
