import _ from 'underscore';
import { A7Router } from '@ark7/utils';
import {
  Carrier,
  ComingEventDescription,
  ComingEventType,
  User,
  UserTags,
} from '@ark7/core-business-models';
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PropertyVisualPictureType } from '@ark7/property-models';
import {
  UserService,
  UserSubscribeComponent,
  VideoPlayComponent,
} from '@ark7/resources-common';
import { interval } from 'rxjs';

import { getCountDownString } from '../lib';

const COMING_TEXT = 'Coming Soon';

@Component({
  selector: 'v2-visual-slide',
  templateUrl: './visual-slide.component.html',
  styleUrls: ['./visual-slide.component.scss'],
})
export class VisualSlideComponent
  extends UserSubscribeComponent<User>
  implements OnInit
{
  @Input() borderRadiusClass = 'v2-radius-8px';
  pics: string[];
  sidePics: string[];
  selected = 0;
  hover = false;
  stripe = '';
  stripeClass = '';
  stripeImg = undefined;

  userTags: UserTags;
  orderStarts = '';
  preOrderStarts = '';
  buttonSize = 20;
  @Input()
  mobile = false;

  comingEvent: ComingEventDescription;

  videoId = '';

  slideOnChange = false;

  private _carrier: Carrier;
  @Input()
  set carrier(c) {
    this._carrier = c;
  }
  get carrier() {
    return this._carrier;
  }

  /*
   * TODO: instead of having modes, just set features individually
   */

  private _mode: 'small' | 'oneThree' | 'trending' = 'small';
  @Input()
  set mode(m) {
    this._mode = m;
  }
  get mode() {
    return this._mode;
  }

  private _showPlus = true;
  @Input()
  set showPlus(v: boolean) {
    this._showPlus = v;
  }

  get showPlus() {
    return (
      this.carrier.isRegDOffering &&
      !['small', 'trending'].includes(this.mode) &&
      this._showPlus
    );
  }

  constructor(
    private router: A7Router,
    userService: UserService,
    private dialog: MatDialog,
  ) {
    super(userService);
  }

  ngOnInit(): void {
    this.pics = this.carrier.visual.pictures
      .filter((pic) => pic.type === PropertyVisualPictureType.PHOTO)

      .map(
        (x) => x.src + (this.mode === 'small' ? '?d=458x288' : '?d=960x604'),
      );

    if (this.mode === 'small') {
      this.pics = this.pics.slice(0, 3);
    } else if (this.mode === 'oneThree') {
      this.sidePics = _.first(this.pics, 4);
      this.sidePics.shift();
    }

    this.videoId = this.carrier.visual.pictures
      .filter((pic) => pic.type === PropertyVisualPictureType.VIDEO)
      .map((x) => x.src.slice('https://youtu.be/'.length))[0];

    this.safeSubscribe(interval(1000), () => this.updateStripe());
  }

  get isUpComing() {
    return this.carrier.isUpComing;
  }

  get guaranteedRateOffer() {
    return this.carrier?.initPacket?.guaranteedRateOffer;
  }
  get hasGuaranteedOffer() {
    return this.carrier?.initPacket?.guaranteedRateOffer?.isActive;
  }

  private _canClick = true;
  @Input() set canClick(v) {
    this._canClick = v;
  }
  get canClick() {
    return this._canClick;
  }

  get canGoDetail() {
    if (!['small', 'trending'].includes(this.mode)) {
      return false;
    }

    if (this.isUpComing) {
      return false;
    }

    return this.canClick;
  }

  get canGoGallery() {
    if (!['oneThree'].includes(this.mode)) {
      return false;
    }
    return this.canClick;
  }

  imgClick() {
    if (this.canGoDetail) {
      this.router.navigate(['/investments', this.carrier._id]);
    }
    if (this.canGoGallery) {
      this.router.navigate(['/investments', this.carrier._id, 'gallery']);
    }
  }

  middleClick() {
    let url = '';
    if (this.canGoDetail) {
      url = this.router.serializeUrl(
        this.router.createUrlTree([`/investments/${this.carrier._id}`]),
      );
    } else if (this.canGoGallery) {
      url = this.router.serializeUrl(
        this.router.createUrlTree([`/investments/${this.carrier._id}/gallery`]),
      );
    }

    if (url) {
      window?.open(url, '_blank');
    }
  }

  onUserUpdate(u: User) {
    if (u != null) {
      this.userTags = u.tags;
    }
  }

  private updateStripe() {
    if (!this.carrier || this.carrier.isSecondaryMarket) {
      return;
    }

    if (this._carrier?.isSoldOut) {
      this.stripe = 'Sold Out';
      this.stripeClass = 'bg-green-grey-2';
    } else if (this.hasGuaranteedOffer) {
      if (['small', 'trending'].includes(this.mode)) {
        this.stripe = 'Special Offer';
        this.stripeClass = 'bg-red-6';
        this.stripeImg = '/assets/guarentee_time.svg';
      } else {
        // if has guaranteed offer, do not show other stripe on big mod
        return;
      }
    } else if (this._carrier?.ipoProgress > 0.75) {
      this.stripe = 'Almost Sold Out';
      this.stripeClass = 'bg-warn';
    } else if (this._carrier.isUpComing) {
      this.stripe = COMING_TEXT;
      this.stripeClass = 'bg-500';
    } else if (this._carrier.displayTag != null) {
      this.stripe = this._carrier.displayTag;
      if (this._carrier.displayTag === 'Airbnb') {
        this.stripeImg = '/assets/airbnb-tag.svg';
        this.stripeClass = 'bg-white-80 color-airbnb';
      } else {
        this.stripeClass = 'bg-500';
      }
    } else {
      if (!this.userTags) {
        return;
      }

      this.comingEvent = this.carrier.comingEventDescription(this.userTags);

      if ([ComingEventType.PUBLIC].includes(this.comingEvent?.type)) {
        this.stripe = COMING_TEXT;
        this.stripeClass = 'bg-500';
        this.orderStarts = getCountDownString(
          this.comingEvent.millisecondsLeft,
        );
      } else if ([ComingEventType.PRIVATE].includes(this.comingEvent?.type)) {
        this.stripe = COMING_TEXT;
        this.stripeClass = 'bg-500';
        this.preOrderStarts = getCountDownString(
          this.comingEvent.millisecondsLeft,
        );
      } else if (
        [ComingEventType.PRIVATE_END].includes(this.comingEvent?.type)
      ) {
        this.stripe = 'Pre-Order';
        this.stripeClass = 'bg-500';
      }
    }
  }

  openVideo() {
    this.dialog.open(VideoPlayComponent, {
      width: '100vw',
      height: '100vh',
      panelClass: 'fullscreen-dialog',
      backdropClass: 'fullscreen-dialog-backdrop',
      maxWidth: '100vw',
      data: { videoId: this.videoId },
    });
  }
}
