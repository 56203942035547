<div [ngClass]="[
    'full-width block smooth relative pointer v2-radius-8px v2-min-h-4 bg-blue-grey-1 overflow-hidden z-index-higher v2-padding-2-r',
    expanded ? 'v2-border-1px border-primary' : ''
  ]"
     (click)="expanded = !expanded">
  <div class="absolute v2-right-1 v2-top-1 v2-w-2 v2-h-2 v2-radius-8px smooth center-inner click-through"
       [ngStyle]="{
      transform: expanded ? 'translate(0px, 4px)' : 'rotate(180deg) translate(0px, 4px)'
    }">
    &#8963;
  </div>
  <div class="smooth v2-padding-2-r ">

    <ng-container *ngIf="!expanded">
      <ng-container *ngIf="optionTemplate; else defaultOptionTemplate">
        <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: option, index: selected }">
        </ng-container>
      </ng-container>
      <ng-template #defaultOptionTemplate>
        <div class="v2-padding-1-v v2-padding-2-h nowrap overflow-hidden text-overflow-ellipsis">
          {{ option?.label ? option?.label : (option | case: 'title') }}
        </div>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="expanded">
      <div *ngFor="let option of choices; let i = index"
           (click)="selected = i;">
        <ng-container *ngIf="optionTemplate; else defaultOptionTemplate">
          <ng-container *ngTemplateOutlet="optionTemplate; context: { $implicit: option, index: i }">
          </ng-container>
        </ng-container>
        <ng-template #defaultOptionTemplate>
          <div class="v2-padding-1-v v2-padding-2-h nowrap overflow-hidden text-overflow-ellipsis">
            {{ option?.label ? option?.label : (option | case: 'title') }}
          </div>
        </ng-template>
      </div>
    </ng-container>
  </div>
</div>
