import { A7LayoutService, V2BaseComponent } from '@ark7/layout';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { VideoPlayComponent } from '@ark7/resources-common';

@Component({
  selector: 'v2-home-stats',
  templateUrl: './home-stats.component.html',
  styleUrls: ['./home-stats.component.scss'],
})
export class HomeStatsComponent extends V2BaseComponent implements OnInit {
  stats = [
    {
      label: 'Active Investors',
      value: '200K+',
    },
    {
      label: 'Property Value Funded',
      value: '$21MM+',
    },
    {
      label: 'Cash Distributions Paid',
      value: '$2.9MM+',
      videoId: '8lnkiRhuwl4',
    },
  ];

  ngOnInit() {}

  constructor(
    protected layoutService: A7LayoutService,
    private dialog: MatDialog,
  ) {
    super(layoutService);
  }

  bgOptions = {
    path: '/assets/home-2mm-dividend-bg.json',
    loop: false,
    autoplay: true,
  };

  onPlayVideo(videoId: string) {
    const videoWidth = Math.min(this.layoutService.currentWidth, 800);
    const videoHeight = (videoWidth / 800) * 450;

    this.dialog.open(VideoPlayComponent, {
      width: `${videoWidth}px`,
      height: `${videoHeight + 120}px`,
      maxWidth: '100vw',
      data: {
        videoId,
        width: `${videoWidth}px`,
        height: `${videoHeight}px`,
      },
    });
  }
}
