<div [ngClass]="[
    v2PaddingH,
    'v2-row-gap-32px',
    sm
      ? 'flex-column-between-center v2-max-w-53'
      : 'flex-row-between-center v2-max-w-120 ' + colGap,
    sm ? '' : sectionClass
  ]">
  <div [ngClass]="[
      sm ? 'flex-column-between-center' : 'flex-scale-basis-0',
      'v2-max-w-54'
    ]">
    <div [ngClass]="[
        paddingLeft,
        sm ? 'flex-column-between-center' : ''
      ]">
      <h1 [ngClass]="[
          'color-green-grey-1',
          headlineClass,
          sm ? 'text-center' : ''
        ]">
        {{headline}}
        <span *ngIf="showStarSign"
              class="h3 color-green-grey-3 vertical-align-top">*</span>
      </h1>

      <div class="v2-margin-16px-t"
           *ngIf="!sm">
        <div [ngClass]="['body-1 color-green-grey-2']"
             *ngFor="let p of points">
          {{ p }}
        </div>
      </div>
      <div class="v2-margin-1-t"
           *ngIf="sm">
        <div [ngClass]="['body-1 color-green-grey-2 text-center']">
          {{ points.join(' ') }}
        </div>
      </div>

      <div #ref
           [ngClass]="[
          ref.children.length > 0 ? 'center v2-margin-32px-t' : '',
          sm
            ? 'flex-column-between-center v2-row-gap-1'
            : 'flex-row-start'
        ]">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
  <div [ngClass]="[sm ? 'v2-neg-margin-2-h' : 'flex-scale-basis-0']"
       class="flex-row-end-center">
    <img *ngIf="imgSrc"
         (load)="loaded = true"
         [src]="imgSrc"
         [alt]="imgAlt"
         [ngClass]="[
        'full-width',
        sm ? 'v2-max-h-33 v2-max-w-33' : 'v2-max-h-48 v2-max-w-48',
        sm ? '' : imageClass
      ]" />

    <svg *ngIf="!loaded"
         width="576"
         height="576"
         [ngClass]="[
    'full-width',
    sm ? 'v2-max-h-33 v2-max-w-33' : 'v2-max-h-48 v2-max-w-48',
    sm ? '' : imageClass
  ]">
      <rect width="
         100%"
            height="100%"
            fill="transparent" />
    </svg>
  </div>
</div>
